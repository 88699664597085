var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.valores !== null)?_c('v-data-table',{attrs:{"dense":"","fixed-header":"","multi-sort":"","headers":_vm.headers,"items":_vm.valores,"locale":"es-es","loading":_vm.cargando,"loading-text":"Cargando... Por favor espere","items-per-page":10,"mobile-breakpoint":870,"footer-props":{
    showFirstLastPage: true,
    firstIcon: 'mdi-arrow-collapse-left',
    lastIcon: 'mdi-arrow-collapse-right',
    prevIcon: 'mdi-minus',
    nextIcon: 'mdi-plus',
    'items-per-page-options': [15, 30, 50, 100],
    'items-per-page-text': 'Filas por página'
  }},scopedSlots:_vm._u([{key:"item.contractdate",fn:function({ item }){return [_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.contractdate),expression:"item.contractdate"}],staticClass:"white--text",attrs:{"small":"","color":"green","label":""}},[_vm._v(" "+_vm._s(item.contractdate)+" ")])]}},{key:"item.notificationdate",fn:function({ item }){return [_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.notificationdate),expression:"item.notificationdate"}],staticClass:"white--text",attrs:{"small":"","color":"orange","label":""}},[_vm._v(" "+_vm._s(item.notificationdate)+" ")])]}},{key:"item.name",fn:function({ item }){return [_c('v-chip',{staticClass:"black--text",attrs:{"small":"","color":"gray","label":""}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.acciones",fn:function({ item }){return [_c('v-btn',{staticClass:"white--text float-right",attrs:{"color":"blue","x-small":""},on:{"click":function($event){return _vm.$router.push({ name: 'fdetalle', params: { id: item.id } })}}},[_vm._v(" ver "),_c('v-icon',{staticClass:"pl-2 float-right",attrs:{"right":"","small":"","color":"white"}},[_vm._v("mdi-open-in-new")])],1)]}}],null,false,3451598173)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }