<template>
  <v-data-table
    dense
    fixed-header
    v-if="valores !== null"
    multi-sort
    :headers="headers"
    :items="valores"
    locale="es-es"
    :loading="cargando"
    loading-text="Cargando... Por favor espere"
    :items-per-page="10"
    :mobile-breakpoint="870"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-minus',
      nextIcon: 'mdi-plus',
      'items-per-page-options': [15, 30, 50, 100],
      'items-per-page-text': 'Filas por página'
    }"
  >
    <template v-slot:item.contractdate="{ item }">
      <v-chip
        small
        class="white--text"
        color="green"
        label
        v-show="item.contractdate"
      >
        {{ item.contractdate }}
      </v-chip>
    </template>
    <template v-slot:item.notificationdate="{ item }">
      <v-chip
        small
        class="white--text"
        color="orange"
        label
        v-show="item.notificationdate"
      >
        {{ item.notificationdate }}
      </v-chip>
    </template>

    <template v-slot:item.name="{ item }">
      <v-chip small class="black--text" color="gray" label>
        {{ item.name }}
      </v-chip>
    </template>
    <template v-slot:item.acciones="{ item }">
      <v-btn
        class="white--text float-right"
        color="blue"
        x-small
        @click="$router.push({ name: 'fdetalle', params: { id: item.id } })"
      >
        ver
        <v-icon right class="pl-2 float-right" small color="white"
          >mdi-open-in-new</v-icon
        >
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "Ficha-table",
  props: ["valores", "cargando"],
  data: () => ({
    headers: [
      {
        text: "Nombre",
        align: "left",
        value: "name",
      },
      {
        text: "Alta Contrato",
        align: "left",
        value: "contractdate",
      },
      {
        text: "Fin Contrato",
        align: "left",
        value: "notificationdate",
      },
      { text: "Acciones", value: "acciones" },
    ],
  }),
  methods: {
    editar(item) {
      this.$router.push({ name: "fdetalle", params: { id: item.id } });
    },
  },
  mounted() {},
};
</script>

<style scoped>
/* .v-data-table th {
  font-size: 20px;
} */

::v-deep.v-data-table td {
  font-size: 0.76em;
}
::v-deep tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03);
}
::v-deep.v-data-table tr:hover {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
</style>
