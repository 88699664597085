<template>
  <v-container class="fill-height" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="mb-3" elevation="5" outlined>
          <v-toolbar color="indigo darken-4" dark dense>
            <v-toolbar-title>Contratos</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-list-item three-line>
            <v-list-item-content>
              <v-row>
                <v-col cols="12" md="4" class="pb-0">
                  <v-text-field
                    v-model="filtro.name"
                    label="Nombre"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2" class="pb-0">
                  <v-text-field
                    v-model="filtro.contractdate_ini"
                    type="date"
                    label="Fecha Alta (desde)"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2" class="pb-0">
                  <v-text-field
                    v-model="filtro.contractdate_end"
                    type="date"
                    label="Fecha Alta (hasta)"
                    dense
                    outlined
                    class="text-right"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2" class="pb-0">
                  <v-text-field
                    v-model="filtro.notificationdate_ini"
                    type="date"
                    label="Fecha Fin Contrato (desde)"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    v-model="filtro.notificationdate_end"
                    type="date"
                    label="Fecha Fin Contrato (hasta)"
                    dense
                    outlined
                    class="text-right"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" class="pt-0">
                  <v-tooltip top color="blue">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="float-right ml-1"
                        color="blue"
                        @click="refrescarDatos"
                        dark
                        v-on="on"
                      >
                        <v-icon dark>mdi-autorenew</v-icon>
                      </v-btn>
                    </template>
                    <span>Actualizar datos</span>
                  </v-tooltip>
                  <v-tooltip left color="purple">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="float-right"
                        color="purple"
                        @click="resetFiltros"
                        dark
                        v-on="on"
                      >
                        <v-icon dark>mdi-close</v-icon>
                      </v-btn>
                    </template>
                    <span>Limpiar formulario</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row v-if="loading"> </v-row>
            </v-list-item-content>
          </v-list-item>

          <v-row>
            <v-col cols="12" md="12">
              <contratos-table
                :valores="valores"
                :cargando="loading"
              ></contratos-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import ContratosTable from "@/components/contratos/parts/ContratosTable";

import { debounce } from "lodash";
import moment from "moment";

export default {
  name: "fichas",
  metaInfo: {
    title: 'Contratos'
  },

  components: {
    ContratosTable,
  },

  data: () => ({
    loading: true,
    datos: null,
    datosTratados: null,
    estados: null,
    categorias: null,
    subCategorias: null,
    filtro: {
      name: null,
      contractdate_ini: null,
      contractdate_end: null,
      notificationdate_ini: null,
      notificationdate_end: null,
      specialties: null,
      subCategorias: null,
    },
  }),

  methods: {
    ...mapActions("contratos", ["setContratosAll"]),
    /* funcion para generar un array con los datos estáticos - agilidad filtrando */
    generarDatosTratados() {
      const salida = this.datos.reduce((acc, curr) => {
        const fechaContrato = moment(curr.reco_contractdate).isValid()
          ? moment(curr.reco_contractdate).format("DD/MM/YYYY")
          : null;
        const fechaFin = moment(curr.reco_notificationdate).isValid()
          ? moment(curr.reco_notificationdate).format("DD/MM/YYYY")
          : null;
        acc.push({
          id: curr.reco_id,
          name: curr.reco_name,
          contractdate: fechaContrato,
          notificationdate: fechaFin,
        });
        return acc;
      }, []);

      this.datosTratados = salida;
    },
    /* funcion para refrescar los datos de la bd */
    refrescarDatos() {
      this.resetFiltros();
      this.$store.commit("comun/SET_MAIN_LOADER", true);
      this.setContratosAll().then((res) => {
        this.datos = res.contratos;

        //generamos un array estático con todos los datos descargados - para después tratarlo con los filtros
        this.generarDatosTratados();
        this.loading = false;
        this.$store.commit("comun/SET_MAIN_LOADER", false);
      });
    },

    /* funcion para limpiar los filtros */
    resetFiltros() {
      Object.keys(this.filtro).forEach((e) => (this.filtro[e] = null));
    },
  },
  computed: {
    /* categorias */
    categoriesItems() {
      if (this.categorias !== null) {
        return this.categorias.reduce((acc, curr) => {
          acc.push({ id: curr.cate_id, name: curr.cate_name });
          return acc;
        }, []);
      }
    },
    /* status */
    statusesItems() {
      if (this.estados !== null) {
        return this.estados.map((c) => c.stat_name);
      }
    },
    valores() {
      if (this.datos !== null && this.datosTratados !== null) {
        //checkeamos si tenemos filtros
        let tmpFiltro = {};

        Object.keys(this.filtro).forEach((e) => {
          //console.log(e, this.filtro[e], !!this.filtro[e])
          if (
            !!this.filtro[e] &&
            (this.filtro[e].length > 0 || Number.isInteger(this.filtro[e]))
          ) {
            // console.log("entro", e, this.filtro[e]);
            const tKey = e;
            tmpFiltro[tKey] = this.filtro[e];
          }
        });
        //console.log(tmpFiltro);

        return filtros(this.datosTratados, tmpFiltro);
      }
      return null;
    },
  },
  mounted() {
    this.refrescarDatos();
  },
};

/* funcion para filtrar los datos */
const filtros = (data, filtros) => {
  var filterKeys = Object.keys(filtros);
  //console.log(filterKeys, filtros);
  const salida = data.filter(function (eachObj) {
    return filterKeys.every(function (eachKey) {
      //comprobamos que el filtro actual no esté vacio
      if (!filtros[eachKey].length && !Number.isInteger(filtros[eachKey])) {
        return true;
      }

      //comprobamos que el filtro actual sea o no de fecha
      if (eachKey.includes("contractdate")) {
        return compararFechas(
          filtros.contractdate_ini,
          filtros.contractdate_end,
          eachObj.contractdate
        );
      }

      //comprobamos que el filtro actual sea o no de fecha
      if (eachKey.includes("notificationdate")) {
        return compararFechas(
          filtros.notificationdate_ini,
          filtros.notificationdate_end,
          eachObj.notificationdate
        );
      }

      //comprobamos si es un filtro de especialidades
      if (eachKey === "specialties") {
        if (eachObj[eachKey].indexOf(filtros[eachKey]) !== -1) {
          return true;
        } else {
          return false;
        }
      }
      //comparamos un texto cualquiera
      if (!!compararRegexStr(filtros[eachKey], eachObj[eachKey])) {
        return true;
      }
    });
  });
  return salida;
};
/* funcion para comparar fechas de los filtros */
const compararFechas = (fechaIni, fechaFin, fechaAlta) => {
  //si la fecha de inicio no está seteada devolvemos true y no comparamos
  if (!fechaIni) {
    return true;
  }

  // console.log(fechaIni, !fechaIni, fechaFin, !fechaFin, fechaAlta);
  //si no hay fecha fin comparamos las fechas mayor e igula que fechaAlta
  if (!fechaFin) {
    const mFechaIni = moment(fechaIni, "YYYY-MM-DD");
    const mFechaAlta = moment(fechaAlta, "DD/MM/YYYY");
    //console.log(mFechaAlta.isAfter(mFechaIni));
    return mFechaAlta.isSameOrAfter(mFechaIni);
  }
  //si está seteada tbn la fecha de fin
  const mFechaIni = moment(fechaIni, "YYYY-MM-DD");
  const mFechaAlta = moment(fechaAlta, "DD/MM/YYYY");
  const mFechaFin = moment(fechaFin).add(1, "d").format("YYYY-MM-DD");
  //console.log(mFechaAlta.isBefore(mFechaFin), mFechaAlta.month(), mFechaAlta.date());
  return mFechaAlta.isSameOrAfter(mFechaIni) && mFechaAlta.isBefore(mFechaFin);

  return true;
};
/* funcion para comparar via regex los strings */
const compararRegexStr = (cadena, texto) => {
  let pattern = texto.toLowerCase();
  pattern = pattern.replace(/[á]/, "a");
  pattern = pattern.replace(/[é]/, "e");
  pattern = pattern.replace(/[í]/, "i");
  pattern = pattern.replace(/[ó]/, "o");
  pattern = pattern.replace(/[ú]/, "u");

  let _value_ = cadena.toLowerCase();
  _value_ = _value_.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  _value_ = _value_.replace(/[á]/, "a");
  _value_ = _value_.replace(/[é]/, "e");
  _value_ = _value_.replace(/[í]/, "i");
  _value_ = _value_.replace(/[ó]/, "o");
  _value_ = _value_.replace(/[ú]/, "u");
  _value_ = new RegExp(_value_, "gi");

  const salida = pattern.match(_value_);
  if (salida !== null) {
    return salida;
  }
};
</script>

<style scoped>
.v-input {
  font-size: 0.9em;
}
/deep/.v-label {
  font-size: 1em;
}
</style>
